import { DeviceState } from '@app/resources/ngrx/states';
import { deviceAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { DeviceActions } from '@app/resources/ngrx/actions';
import * as fromDeviceAdapter from '@app/resources/ngrx/adapters';

export const initialDeviceModuleState: DeviceState = deviceAdapter.getInitialState({
  selectedDeviceId: 0,
  selectId: 0,
});

export const _deviceReducer = createReducer(
  initialDeviceModuleState,
  on(DeviceActions.upsertDeviceSuccess, (state, { payload }) => {
    return fromDeviceAdapter.deviceAdapter.upsertOne(payload.device, state);
  }),
  on(DeviceActions.updateDeviceTrackingsSuccess, (state, { payload }) => {
    if (payload.deviceTrackings.length === 0) return state;

    const deviceId = payload.deviceTrackings[0].DeviceId;
    const updatedDevice = {
      ...state.entities[deviceId],
      DeviceTrackings: payload.deviceTrackings,
    };
    return fromDeviceAdapter.deviceAdapter.updateOne({ id: deviceId, changes: updatedDevice }, state);
  }),
  on(DeviceActions.loadDevicesSuccess, (state, { payload }) => {
    state = fromDeviceAdapter.deviceAdapter.removeAll({ ...state });
    return fromDeviceAdapter.deviceAdapter.addMany(payload.devices, state);
  }),
  on(DeviceActions.deviceById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedDeviceId: payload.deviceId,
    });
  })
);
