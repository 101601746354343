import { Type } from '@angular/core';
import { FunctionalEffect } from '@ngrx/effects/src/models';
import { HydrationEffects } from '@app/resources/ngrx/effects/hydration.effects';
import { MessagingEffects } from '@app/resources/ngrx/effects/messaging.effects';
import { RouterEffects } from '@app/resources/ngrx/effects/router.effects';

export const effects: Array<Type<unknown> | Record<string, FunctionalEffect>> = [
  HydrationEffects,
  MessagingEffects,
  RouterEffects,
];
