import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RootState } from '@app/resources/ngrx/states';
import {
  _applicationProfileReducer,
  _deviceReducer,
  _loyaltyProgramReducer,
  _questionReducer,
  hydrationMetaReducer,
} from '@app/resources/ngrx/reducers';
import { routerReducer } from '@ngrx/router-store';

export const reducers: ActionReducerMap<RootState> = {
  applicationProfile: _applicationProfileReducer,
  device: _deviceReducer,
  loyaltyProgram: _loyaltyProgramReducer,
  questions: _questionReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
