/**
 * Enum defining the various SignalR target events.
 *
 * @enum {string}
 * @readonly
 */
export enum SignalrTargetsEnum {
  UpdateDashboard = 'onDashboardUpdate',
  UpdateApplicationProfile = 'onApplicationProfileUpdate',
  CreateLocation = 'onLocationCreate',
  UpdateLocation = 'onLocationUpdate',
  DeleteLocation = 'onLocationDelete',
  CreateEmployee = 'onEmployeeCreate',
  UpdateEmployee = 'onEmployeeUpdate',
  DeleteEmployee = 'onEmployeeDelete',
  CreateConsumer = 'onConsumerCreate',
  UpdateConsumer = 'onConsumerUpdate',
  DeleteConsumer = 'onConsumerDelete',
  onDeviceChange = 'onDeviceChange',
  onDeviceTrackingChange = 'onDeviceTrackingChange',
  CreateOffer = 'onOfferCreate',
  UpdateOffer = 'onOfferUpdate',
  DeleteOffer = 'onOfferDelete',
  onQuestionChange = 'onQuestionChange',
  onLoyaltyProgramChange = 'onLoyaltyProgramChange',
  CreateOrder = 'onOrderCreate',
  UpdateOrder = 'onOrderUpdate',
  DeleteOrder = 'onOrderDelete',
}
