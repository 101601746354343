<div *appLoading="isLoading" class="admin-cards-container">
  <ng-container *ngFor="let card of cardConfigurations; trackBy: trackByHeader">
    <ep-view-card
      *ngIf="hasPermission(card.accessType)"
      [config]="card.config"
      (click)="navigateToPath(card.path)"
      class="admin-card"
      [attr.data-cy]="'admin-card-' + card.path"
    ></ep-view-card>
  </ng-container>
</div>
