import { Component, inject, OnInit } from '@angular/core';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgForOf, NgIf } from '@angular/common';
import { ConfigurationService, LoadingSpinnerDirective, ViewCardComponent } from '@ep/shared';
import { ColorsEnum, EmployeeAccessTypes } from '@shared/enums';
import { ViewCardConfig } from '@ep/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

export interface AdminCardConfig {
  config: ViewCardConfig;
  accessType: EmployeeAccessTypes;
  path: string;
}

@UntilDestroy()
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  standalone: true,
  imports: [NgForOf, NgIf, LoadingSpinnerDirective, ViewCardComponent],
})
export class AdminComponent implements OnInit {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly iframeManagerService = inject(IframeManagerService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  locationId: string | null = null;
  isLoading: boolean = false;

  cardConfigurations: AdminCardConfig[] = [
    {
      config: {
        header: 'Devices',
        subheader: 'Configure',
        pathToImg: '/assets/images/handshake.png',
        backgroundColor: ColorsEnum.LIGHT_BLUE,
      },
      accessType: EmployeeAccessTypes.DeviceAdmin,
      path: 'devices',
    },
    {
      config: {
        header: 'Locations',
        subheader: 'Configure',
        pathToImg: '/assets/images/line-graph.png',
        backgroundColor: ColorsEnum.MOSSY_GREEN,
      },
      accessType: EmployeeAccessTypes.LocationAdmin,
      path: 'locations',
    },
    {
      config: {
        header: 'Employees',
        subheader: 'Configure',
        pathToImg: '/assets/images/thumbs-up.png',
        backgroundColor: ColorsEnum.DOC_PURPLE,
      },
      accessType: EmployeeAccessTypes.EmployeeAdmin,
      path: 'employees',
    },
    {
      config: {
        header: 'Loyalty Program',
        subheader: 'Configure',
        pathToImg: '/assets/images/star.png',
        backgroundColor: ColorsEnum.BLUE_GREY,
      },
      accessType: EmployeeAccessTypes.LoyaltyProgramAdmin,
      path: 'loyalty-program',
    },
    {
      config: {
        header: 'Questions',
        subheader: 'Configure',
        pathToImg: '/assets/images/handshake.png',
        backgroundColor: ColorsEnum.LIGHT_BLUE,
      },
      accessType: EmployeeAccessTypes.QuestionAdmin,
      path: 'questions',
    },
    {
      config: {
        header: 'Billing',
        subheader: 'Configure',
        pathToImg: '/assets/images/handshake.png',
        backgroundColor: ColorsEnum.MOSSY_GREEN,
      },
      accessType: EmployeeAccessTypes.BillingAdmin,
      path: 'billing',
    },
  ];

  private applicationProfile: ApplicationProfile | null = null;
  private subs = new Subscription();

  ngOnInit() {
    this.subs.add(
      this.applicationProfileService.applicationProfile$.pipe(untilDestroyed(this)).subscribe((applicationProfile) => {
        this.applicationProfile = applicationProfile;
      })
    );
  }

  protected trackByHeader(index: number, item: AdminCardConfig) {
    return item.config.header;
  }

  protected hasPermission(accessType: EmployeeAccessTypes) {
    if (this.applicationProfile) {
      const employeeAccess = this.applicationProfile.Employee.AccessType & accessType;
      return employeeAccess === accessType;
    }
    return false;
  }

  navigateToPath(path: string) {
    if (
      !path.includes('locations') &&
      !path.includes('devices') &&
      !path.includes('loyalty-program') &&
      !path.includes('questions')
    ) {
      const iframe = this.iframeManagerService.generateIframeSrc(
        this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
        this.applicationProfileService.getApplicationProfile().Location.LocationId,
        path.toLowerCase()
      );

      this.iframeManagerService.setIframeSrc(iframe);
    }

    this.router.navigate([path], { relativeTo: this.route });
  }
}
